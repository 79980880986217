<template>
    <div class="brand-sample">
        <b-row >

            <b-col cols="4" class="mt-2">
                <b-card header="Typography">
                    <h1>Header 1</h1>
                    <h2>Header 2</h2>
                    <h3>Header 3</h3>
                    <p>Paragraph</p>
                    <small>Small text</small>
                </b-card>
            </b-col>

            <b-col cols="4" class="mt-2">
                <b-card header="Buttons">
                    <div class="buttons">
                        <p>Active</p>
                        <b-button variant="primary">Primary</b-button>
                        <b-button variant="secondary">Secondary</b-button>
                        <b-button variant="info">Info</b-button>
                        <b-button variant="success">Success</b-button>
                        <b-button variant="warning">Warning</b-button>
                        <b-button variant="danger">Danger</b-button><br /><br />
                        <p>Disabled</p>
                        <b-button variant="primary" disabled>Primary</b-button>
                        <b-button variant="secondary" disabled>Secondary</b-button>
                        <b-button variant="info" disabled>Info</b-button>
                        <b-button variant="success" disabled>Success</b-button>
                        <b-button variant="warning" disabled>Warning</b-button>
                        <b-button variant="danger" disabled>Danger</b-button>
                    </div>
                </b-card>
            </b-col>

            <b-col cols="4" class="mt-2">
                <b-card header="Progress / Loaders">
                    <div class="progress-container">
                        <b-progress variant="primary" striped animated :value="50"></b-progress>
                        <b-progress variant="danger" :value="50"></b-progress>
                        <b-progress variant="warning" :value="50"></b-progress>
                        <b-progress variant="info" :value="50"></b-progress>
                    </div>
                    <div class="loaders mt-2">
                        <Pulse />
                    </div>
                </b-card>
            </b-col>

            <b-col cols="12" class="mt-2">
                <b-card header="Custom Components">
                    <b-row>
                        <b-col sm="3">
                            <TopStatistic header="Loaded" :loading="false" value="100"></TopStatistic>
                        </b-col>
                        <b-col sm="3">
                            <TopStatistic header="Loading" :loading="true" value="100"></TopStatistic>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

        </b-row>

    </div>
</template>

<script>
    import Pulse from 'cpdcloud-dashboard/src/components/loaders/Pulse'
    import TopStatistic from 'cpdcloud-dashboard/src/components/common/TopStatistic'

    export default {
        name: "Brand",
        components: {TopStatistic, Pulse}
    }
</script>

<style scoped>
    .brand-sample {
        padding: 20px;
    }
    .buttons>* {
        margin-top: 5px;
        margin-left: 10px;
    }
    .progress-container>* {
        margin-top: 2px;
    }
    div.col {
        margin-top: 10px;
    }
</style>